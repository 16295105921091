<!--
 * @description: 通用新增弹窗组件
 * @author: Long
 * @version: 1.4
 * @updateDate:2022-10-27 新增腾讯地图搜索功能
 * @updateDate:2022-11-9 拓展定时任务组件，可配置多选
 * @updateDate:2023-1-16 新增更换区域树形下拉选择
 * @description: 简单的用法如下:
    generalform:表单数据     FormDialog:表单控制  FormList:表单列数据  addTitle:标题  uploadSuccess:上传成功操作  cancelDialog:取消  confirmDialog:表单提交
    isUpload:输入导入  isUploadModel:模板上传    isSpanAll:列是否占一行
    <general-form :FormDialog="FormDialog" :FormList="FormList" :title="addTitle" :FormData="FormData" @uploadSuccess="uploadSuccess" @cancelDialog="cancelDialog" @confirmDialog="confirmDialog" :isUpload='true' :isUploadModel='true' :isSpanAll="true"></general-form>
    
-->
<template>
  <el-dialog class="dialog-mini user-dialog" :title="title" :visible.sync="fatherFormDialog" :width="isSpanAll?'30%':'50%'" @close="closeDialog">
    <el-form :model="FormData" label-width="120px" ref="generalForm" :rules="FormList.rule">
      <el-row>
        <el-col :span="isSpanAll?24:12" v-for="(i,index) in FormList.column" :key="index">
          <template v-if="i.columnType != 'upload'">
            <!-- 地图搜索-->
            <el-form-item  v-if="i.columnType == 'text' && i.isMap" :label="i.columnDescription" :prop="i.prop">
              <el-autocomplete style="width:100%" v-model="FormData[i.columnKey]" popper-class="autoAddressClass el-input" :placeholder="i.columnDescription" :disabled="i.disabled" :fetch-suggestions="toSearch" :trigger-on-focus="false" clearable @select="handleSelect" @clear="clear" >
                <i class="el-icon-edit el-input__icon" slot="suffix"></i>
                <template slot-scope="{ item }">
                  <div class="autoname">{{ item.title }}</div>
                  <span class="autoaddress">{{ item.address }}</span>
                </template>
              </el-autocomplete>
            </el-form-item>
            

            <!-- 选择坐标 地图搜索-->
            <el-form-item  v-if="i.columnType == 'text' && i.isChooseCoordinates" :label="i.columnDescription" :prop="i.prop">
              <el-input v-model="FormData[i.columnKey]" :disabled="i.disabled" @focus='showMap = true'></el-input>
              <TMap @cancel="showMap = false" @map-confirm="confirmLocation" :visible="showMap" v-if="showMap"></TMap>
            </el-form-item>


            <!-- input -->
            <el-form-item  v-if="i.columnType == 'text' && !i.isMap && !i.isChooseCoordinates" :label="i.columnDescription" :prop="i.prop">
              <el-input v-model="FormData[i.columnKey]" :disabled="i.disabled"></el-input>
            </el-form-item>

            

            <!-- num -->
            <el-form-item  v-if="i.columnType == 'num'" :label="i.columnDescription" :prop="i.prop">
              <el-input-number  :step="i.step?i.step:1" step-strictly   type="text"  size="mini" :disabled="i.disabled" v-model="FormData[i.columnKey]" :min="0" style="width:100%"> </el-input-number>
            </el-form-item>

            <!-- textarea -->
            <el-form-item  v-if="i.columnType == 'textarea' && !i.isMap && !i.isChooseCoordinates" :label="i.columnDescription" :prop="i.prop" style="height:auto">
              <el-input v-model="FormData[i.columnKey]" :disabled="i.disabled" type="textarea" :rows="5"></el-input>
            </el-form-item>

            <!-- CRON表达式 -->
            <el-form-item  v-if="i.columnType == 'cron'" :label="i.columnDescription" :prop="i.prop">
              <cron-input v-model="FormData[i.columnKey]"></cron-input>
            </el-form-item>

            <!-- select -->
            <!-- <el-form-item  v-else-if="i.columnType == 'select'" :label="i.columnDescription" :prop="i.prop">
              <el-select  clearable :disabled="i.disabled" v-model="FormData[i.columnKey]" style="width:100%">
                <el-option v-for="(j, jdx) in i.seleceOption" :key="jdx" :value="j.dtCode" :label="j.name"></el-option>
              </el-select>
            </el-form-item> -->
            <!-- selectProject 项目特殊处理下拉 -->
            <el-form-item  v-else-if="i.columnType == 'selectProject'" :label="i.columnDescription" :prop="i.prop">
              <el-select  clearable :disabled="i.disabled" v-model="FormData[i.columnKey]" style="width:100%" filterable>
                <el-option v-for="(j, jdx) in AllProject" :key="jdx" :value="j.projectCode" :label="j.projectName"></el-option>
              </el-select>
            </el-form-item>
            <!-- selectArea 区域特殊处理下拉 -->
            <el-form-item  v-else-if="i.columnType == 'selectArea'" :label="i.columnDescription" :prop="i.prop">
              <el-select  clearable :disabled="i.disabled" v-model="FormData[i.columnKey]" style="width:100%" filterable>
                <el-option v-for="(j, jdx) in allArea" :key="jdx" :value="j.id" :label="j.areaName"></el-option>
              </el-select>
            </el-form-item>
            <!-- selectAreaTree 区域特殊处理下拉 - 树形区域 -->
            <el-form-item  v-else-if="i.columnType == 'selectAreaTree'" :label="i.columnDescription" :prop="i.prop">
              <!-- <el-select  clearable :disabled="i.disabled" v-model="FormData[i.columnKey]" style="width:100%" filterable>
                <el-option v-for="(j, jdx) in allArea" :key="jdx" :value="j.id" :label="j.areaName"></el-option>
              </el-select> -->
              <treeselect ref="orgsTree" :options="allArea" :default-expand-level="3" :multiple="false" :open-on-click="true" :open-on-focus="true" :clear-on-select="true" v-model="FormData[i.columnKey]"></treeselect>
            </el-form-item>

            <!-- selectUser 用户特殊处理下拉 -->
            <el-form-item  v-else-if="i.columnType == 'selectUser'" :label="i.columnDescription" :prop="i.prop">
              <el-select  clearable :disabled="i.disabled" v-model="FormData[i.columnKey]" style="width:100%" filterable>
                <el-option v-for="(j, jdx) in allUser" :key="jdx" :value="j.id" :label="j.name"></el-option>
              </el-select>
            </el-form-item>

            <!-- selectUser 定时任务下拉 -->
            <el-form-item  v-else-if="i.columnType == 'selectTimeTask'" :label="i.columnDescription" :prop="i.prop">
              <el-select  clearable :disabled="i.disabled" v-model="FormData[i.columnKey]" style="width:100%;" filterable :multiple='i.multiple' collapse-tags>
                <el-option v-for="(j, jdx) in allTimeTask" :key="jdx" :value="j.id" :label="j.jobName"></el-option>
              </el-select>
            </el-form-item>


            <!-- select -->
            <el-form-item  v-else-if="i.columnType == 'select'" :label="i.columnDescription" :prop="i.prop">
              <el-select  clearable :disabled="i.disabled" v-model="FormData[i.columnKey]" style="width:100%" v-if="i.typeId" filterable :multiple='i.multiple'>
                <el-option v-for="(j, jdx) in getOptionData(i.typeId)" :key="jdx" :value="j.dtCode" :label="j.name"></el-option>
              </el-select>
              <!-- 没有typeId根据数据取值 -->
              <el-select  clearable :disabled="i.disabled" v-model="FormData[i.columnKey]" style="width:100%" v-else filterable>
                <el-option v-for="(j, jdx) in i.seleceOption" :key="jdx" :value="j.dtCode" :label="j.name"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-col>
        <el-col :span="24" v-for="(i,index) in FormList.column" :key="index+'upload'">
          <template v-if="i.columnType == 'upload'">
            <!-- upload -->
            <el-form-item  v-if="i.columnType == 'upload'" :label="i.columnDescription" :prop="i.prop" style="min-height:250px;">
              <el-upload ref="uploadF" class="upload-demo" action="" :on-preview="handlePreview" :headers="tokenHeader" :on-success="handleSuccess" multiple :limit="limit" :on-exceed="handleExceed" :file-list="fileList"  :auto-upload="false" style="width:100%" drag  :before-upload="beforeUpload" :http-request="httpRequest">
                <!-- <el-input v-model="FormData[i.columnKey]" :disabled="i.disabled"></el-input> -->
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip" style="color:red;">*上传文件不能超过 {{defaultSize}} MB</div>
              </el-upload>
            </el-form-item>
          </template>
        </el-col>
      </el-row>
    </el-form>
    <div style="text-align:right;" slot="footer">
      <el-button size="small" type="cancel" @click="cancelDialog('generalForm')">取消</el-button>
      <el-button size="small" type="primary" @click="dialogConfirm('generalForm')">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {listToTreeSelect} from '@/utils'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import TMap from '@/components/base/TMap'//通用地图组件
import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
import { getToken } from '@/utils/auth'
import CronInput from '@/components/cron/cron-input'
export default {
  name: "generalForm",
  components: {
    TMap,
    CronInput,
    Treeselect
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_API, // 上传的api的base_url
      tokenHeader: {'X-Token': getToken()},//上传用户token
      fileList:[],//上传列表
      fatherFormDialog:this.FormDialog,
      defaultSize:50,//默认文件大小限制
      limit:1,//上传文件数量
      // address: "",
      // mapAddress: [],
      // city:'',
      // value:'',
      showMap:false,
    }
  },
  props:{
    //标题
    title:[String,],
    // 行是否分两列
    isSpanAll:{
      type: Boolean,
      default: false
    },
    // 弹窗
    FormDialog:{
      type: Boolean,
      default: false
    },
    //表列数据
    FormList:[String, Object,Array],
    //数据
    FormData:[String, Object,Array],
    // 是否是数据导入
    isUpload:{
      type: Boolean,
      default: false
    },
    // 是否是模板上传
    isUploadModel:{
      type: Boolean,
      default: false
    },
    // 是否是定时任务
    isDsrw:{
      type: Boolean,
      default: false
    },
    //区域调整当前选择数据,用于所有区域剔除自己
    areaChangeChoose:{
      type:Array,
      default: () => []
    },
    //是否是树形区域选择
    isAreaTree:{
      type: Boolean,
      default: false
    },
    //户表管理更换区域选择数据
    chooseMemterAreaData:[String, Object,Array],
    //总表管理更换区域选择数据
    chooseTotalMemterAreaData:[String, Object,Array],
    
  },
  computed:{
    //获取option数据
    getOptionData(){
      return function(typeId){
        if(this.$store.state.allCategorysData.length <= 0){
          return []
        }else{
          return  this.$store.state.allCategorysData.filter(u => u.id == typeId)[0].categoryList
        }
      }
    },
    //所有项目数据
    AllProject() {
      return this.$store.state.AllProject;
    },
    //所有区域数据
    allArea() {
      // var s
      // if(this.areaChangeChoose.length > 0){//区域管理更换区域
      //   s  = this.$store.state.AllAreaGetTreeId.filter(i => this.areaChangeChoose.some(ele=>i.id != ele.id )) //所有区域中过滤选择的区域
      // }else if(this.chooseMemterAreaData && this.chooseMemterAreaData.length > 0){//户表管理更换区域
      //   s  = this.$store.state.AllAreaGetTreeId
      // }else if(this.chooseTotalMemterAreaData){//总表管理更换区域
      //   s  = this.$store.state.AllAreaGetTreeId.filter(i => i.id != this.chooseTotalMemterAreaData.parentId) //所有区域中过滤选择的区域
      // }else{
      //   s  = this.$store.state.AllAreaGetTreeId
      // }

      // if(this.isAreaTree){
      //   var orgs = s.map(function(item) {
      //     return {
      //       id: item.id,
      //       label: item.areaName,
      //       parentId: item.fatherNode || null
      //     }
      //   })
      //   var orgstmp = JSON.parse(JSON.stringify(orgs))
      //   var t = listToTreeSelect(orgstmp)
      //   return t
      // }else{
      //   return s
      // }
      return []

      
    },
    //所有用户数据
    allUser() {
      return this.$store.state.AllUser;
    },
    //所有定时任务
    allTimeTask() {
      return this.$store.state.HdybScheduledAcquisition;
    },
  },
  created() {
    
  },
  mounted() {
    
  },
  methods: {
    // 选点确定
    confirmLocation(e) {
      var stadiumData={
        latitude:e.lat,
        longitude:e.lng
      }
      this.FormData['geographicalPosition'] = stadiumData.latitude + ',' + stadiumData.longitude
      this.showMap = false
    },

    //远程搜索
    async toSearch(queryString, callback) {
      if (queryString == undefined || queryString == null){
        queryString=''
        }
      var searchData={
        keyword:queryString,
        region:'',
      }
      let lit = []
      await basicDataApi.getTencentMapList(searchData).then(res=>{
        if(res.code=='200'){
          lit = res.result.data
        }else{
          lit=[]
        }
      })
      // lit.forEach(
      //   item=>{item.value = '编码:'+item.goodsId+' —— 名称:'+item.name+' —— 规格:'+item.model}
      // )
      callback(lit)
    },
    //远程搜索选择
    handleSelect(item) {
      this.FormData.longitude = item.location.lng//经度
      this.FormData.latitude = item.location.lat//纬度
      this.FormData.district = item.district//所属县级市
      this.FormData.city = item.city//所属地级市
      this.FormData.province = item.province//所属省份
      this.FormData.detailedAddress = item.title//经度
      // this.$emit("callbackMap", item.modelData);
    },
    clear() {
      this.$emit("callbackMap", "");
    },
    baidu_api_point(path, callback) {
        //跨域调用百度api
        //注意这里挂载在了window上
        window.callbackData = callback;
        // let url =`https://apis.map.qq.com/uri/v1/search?keyword=${path}&region=${this.city}&referer=HZ4BZ-N363U-XIIVQ-2ZUFS-DG75S-KWBBO`
        // let fetchJsonp = function (url) {
        //   let body = document.getElementsByTagName("body")[0];
        //   //插入一个script
        //   let script = document.createElement("script");
        //   script.setAttribute("src", url);
        //   script.setAttribute("id", "mapApi");
        //   body.appendChild(script);
        // };
        // fetchJsonp(url);
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePreview(file) {
      console.log(file);
    },
    //上传文件之前
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < this.defaultSize;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 '+ this.defaultSize + 'MB!');
        return isLt2M;
      }
    },
    // 上传成功
    handleSuccess(response, file){
      if(response.code == 200){
        this.$emit('uploadSuccess', file.name);
      }else{
        this.$message.warning('上传失败，请重试！')
      }
    },
    // 文件超出个数限制
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 ${this.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    // 自定义上传方法
    httpRequest(param){
      if(this.isUpload){//数据导入
        let fd = new FormData()// FormData 对象
        fd.append('Files', param.file)// 文件对象  // 相当于input里取得的files
        fd.append('taskName', this.FormData.taskName)
        fd.append('importType', this.FormData.importType)
        // fd.append('projectCode', this.FormData.projectCode)
        fd.append('projectCode', 'qdhd')
        

        basicDataApi.sjdrAdd(fd).then(response => {
          if(response.code == 200){
            this.$message(response.message)
            this.fileList = []//重置上传列表
            this.$emit('confirmDialog',false);//弹窗关闭
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.$notify({
            message: '上传失败，请重试!',
            type: 'warning',
            duration: 2000
          });
        })
      }else if(this.isUploadModel){//模板上传
        let fd = new FormData()// FormData 对象
        fd.append('Files', param.file)// 文件对象  // 相当于input里取得的files
        fd.append('TemplateType', this.FormData.TemplateType)

        basicDataApi.uploadModel(fd).then(response => {
          if(response.code == 200){
            this.$message(response.message)
            this.fileList = []//重置上传列表
            this.$emit('confirmDialog',false);//弹窗关闭
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.$notify({
            message: '上传失败，请重试!',
            type: 'warning',
            duration: 2000
          });
        })
      }
      
      
    },
    //表单添加确认
    dialogConfirm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.isUpload || this.isUploadModel){//数据导入、模板上传
            this.$refs.uploadF[0].submit();
          }else{
            this.$emit('confirmDialog',false);//弹窗关闭
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 取消
    cancelDialog(formName){
      // this.$refs[formName].resetFields();//重置表单数据
      this.$emit('cancelDialog', false);//弹窗关闭
    },
    //点击右上角关闭
    closeDialog(){
      this.$emit('cancelDialog', false);//弹窗关闭
    },
    
  },
  watch: {
    FormDialog(val){
      this.fatherFormDialog = val;
      if(val && this.isAreaTree){
        if(this.areaChangeChoose.length > 0){//区域管理调整区域
          if(this.areaChangeChoose[0].fatherNode == ""){//解决找不到显示(unknown)的问题
            this.FormData.fatherAreaId = undefined 
          }else{
            this.FormData.fatherAreaId = this.areaChangeChoose[0].fatherNode
          }
        }else if(this.chooseMemterAreaData){//户表管理调整区域
          this.FormData.areaId = this.chooseMemterAreaData[0].areaId
        }else{//总表管理调整区域
          this.FormData.areaId = this.chooseTotalMemterAreaData.areaId
        }
        
      }
    }
  },
}
</script>
<style scoped>
  .autoname{
    height: 25px;
    line-height: 25px;
  }
  .autoaddress{
    font-size: 12px;
    color: #b4b4b4;
  }
</style>
